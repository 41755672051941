import React, { useState, useEffect } from 'react';
import logo from '../assets/Lewanـtransparentـlogo.png';
import Lottie from 'lottie-react';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';
import { SiGooglemaps } from 'react-icons/si';
import { BsCupHot } from 'react-icons/bs';
import { IoCartOutline } from 'react-icons/io5';
import animation from './animation_lmc4mg0l.json';
import { RiTwitterXFill } from "react-icons/ri";
import TypingAnimation from '../Components/TypingAnimation';
import poweredBygoma1 from '../assets/Powered_By_GOMA.png'
import { FaSnapchat } from "react-icons/fa6";

import { RiTiktokLine } from "react-icons/ri";

function Lewan() {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [clickedIcon, setClickedIcon] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setLogoLoaded(true);
    }, 200); // Delay to complete the scale animation
  }, []);

  useEffect(() => {
    if (isTypingDone && logoLoaded) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isTypingDone, logoLoaded]);

  const handleIconClick = (icon, url) => {
    setClickedIcon(icon);
    // window.open(url, "_blank");
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.style.display = 'none'
    document.body.append(a)
    a.click()
  };

  return (
    <div className='overflow-x-hidden'>
    <div className="fixed w-screen h-screen top-0 left-0 bottom-0 right-0 -z-10 bg-[#515050]"></div>
    <div className=" flex flex-col justify-between z-10  w-screen pb-10 top-0 left-0 bottom-0 right-0 overflow-x-hidden">
    
      <header className="text-center p-5">
        <div className={`inline-block p-2 py-7 bg-[#fff] rounded-full transform transition-transform duration-300 ${logoLoaded ? 'scale-100 float-logo' : 'scale-0'}`}>
          <img src={logo} alt="Al-Wazzan Logo" className="block w-28" />
        </div>
        <h1 className={`text-white font-bold mt-1 text-2xl`}>
          <TypingAnimation size='26px' color='#ffff' text="Lewan Restobar" onFinish={() => setIsTypingDone(true)} cursorStyle={{ color: 'white' }} />
        </h1> 
        <p className={`text-[#D9D9D9] text-[14px] font-bold mt-2 transform transition-transform duration-1000 ${logoLoaded ? 'translate-x-0' : '-translate-x-full'}`}>
          Welcome to Lewan Restobar. K S A 🇸🇦 Select the destination you'd like to explore ✨
        </p>
      </header>

      <main className="mb-7">
        <div className="flex flex-col items-center gap-3">
         
        <div className={`gap-3 bg-white p-2 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl transform transition-transform duration-700 delay-450 ${isTypingDone ? 'translate-x-0' : '-translate-x-full'}`}>
        <div className="relative flex text-[#D34436]" onClick={() => handleIconClick('meunu', 'http://menu.lewan-restobar.com/')}>
          <BsCupHot size={30} className={`ml-4 ${clickedIcon === 'instagram' ? 'icon-bounce' : 'icon-shake'}`} />
          <h2 className="absolute top-0 left-[38%] text-[20px] text-[#D34436]">
            <TypingAnimation color='#D34436' text="Our Menu" onFinish={() => setIsTypingDone(true)} />
          </h2>
        </div>
      </div>

          <div className={`gap-3 bg-white p-2 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl transform transition-transform duration-700 delay-450 ${isTypingDone ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="relative flex text-[#D34436]" onClick={() => handleIconClick('instagram', 'https://www.instagram.com/lewan.restobar?igsh=djZtanRzb2Jxc3Vt')}>
              <FaInstagram size={30} className={`ml-4 ${clickedIcon === 'instagram' ? 'icon-bounce' : 'icon-shake'}`} />
              <h2 className="absolute top-0 left-[38%] text-[20px] text-[#D34436]">
                <TypingAnimation color='#D34436' text="Instagram" onFinish={() => setIsTypingDone(true)} />
              </h2>
            </div>
          </div>

          <div className={`gap-3 bg-white p-2 md:w-[35%] sm:w-[50%] w-[80%] mb-40 rounded-lg shadow-xl transform transition-transform duration-700 delay-600 ${isTypingDone ? 'translate-x-0' : 'translate-x-full'}`}>
            <div className="relative flex text-[#D34436]" onClick={() => handleIconClick('twitter', 'https://www.tiktok.com/@lewan.restobar?_t=8pWpTPeUuCF&_r=1')}>
              <RiTiktokLine size={30} className={`ml-4 ${clickedIcon === 'twitter' ? 'icon-bounce' : 'icon-shake'}`} />
              <h2 className="absolute top-0 left-[43%] text-[20px] text-[#D34436]">
                <TypingAnimation color='#D34436' text="Tiktok" onFinish={() => setIsTypingDone(true)} />
              </h2>
            </div> 
          </div>

    {/**      <div className={`gap-3 bg-white p-2 md:w-[35%] sm:w-[50%] w-[80%] rounded-lg shadow-xl transform transition-transform duration-700 delay-750 mb-10 ${isTypingDone ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="relative flex text-[#D34436]" onClick={() => handleIconClick('rate', 'https://search.google.com/local/writereview?placeid=ChIJV1POzTjQwxURDjZwwrOJSWo')}>
              <SiGooglemaps size={30} className={`ml-4 ${clickedIcon === 'rate' ? 'icon-bounce' : 'icon-shake'}`} />
              <h2 className="absolute top-0 left-[43%] text-[20px] text-[#D34436]">
                <TypingAnimation color='#D34436' text="Rate Us" onFinish={() => setIsTypingDone(true)} />
              </h2>
              <Lottie className='w-14 h-14 rotate-180 absolute -top-2 md:left-[70%] left-[75%]' animationData={animation} />
            </div>
          </div>
          */} 
        </div>
      </main>

      <footer className="text-center -mt-24 text-xs text-gray-300">
        <a href="https://social.gomaksa.com/omar" target="_blank" rel="noreferrer" className="hover:underline">
        <div className='flex justify-center'>
         <div className='flex justify-between w-32'>
         <img  className='w-32 ' src={poweredBygoma1}/>
      
        
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.73 99.69">
         <defs>
           <style>
             {`.cls-1 { fill: #0b2a3d; }
               .cls-2 { fill: #b09b7b; }`}
           </style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
           <g id="Layer_1-2" data-name="Layer 1">
             <g>
               <path
                 className="cls-1"
                 d="M0,80.43V0H141.73V67c-35-17.08-84.51-14.78-120.34-1.92A108.57,108.57,0,0,0,5.11,72.69a0,0,0,0,0,0,0C21,64.13,46,58.62,74.11,58.62c1.64,0,3.25,0,4.85.05l1.44,0h.22c.9,0,1.8.05,2.7.1l1.26,0,2,.12h.07l2.36.16c.8.05,1.61.11,2.4.18l2.35.2.1,0,2.29.23c.79.08,1.57.18,2.34.27l1,.13c.95.11,1.88.24,2.82.37l1.1.16,1.64.25,2.47.4,1.83.33.8.16c.73.12,1.46.27,2.18.41l1.55.33c1.56.33,3.09.68,4.59,1l1.68.43,1.54.41q1.4.38,2.76.78l1.52.46c1.66.51,3.26,1,4.82,1.59l1.05.38c.88.32,1.75.66,2.6,1l1.21.5c.32.13.64.25,1,.39l1.37.6c.63.28,1.23.56,1.82.85l1.43.7.49.25V83a50.37,50.37,0,0,0-4.59-3.11L137,79.8l-.6-.37h0a97.07,97.07,0,0,0-15-7.64C84.6,57.44,32.15,56.24,0,80.43Z"
               />
               <path
                 className="cls-2"
                 d="M41.61,41.18H80.37a19.75,19.75,0,0,1,19.75,19.75V99.34a.35.35,0,0,1-.35.35H61.36A19.75,19.75,0,0,1,41.61,79.95V41.18a0,0,0,0,1,0,0Z"
                 transform="translate(141.3 -0.43) rotate(90)"
               />
               <path
                 className="cls-1"
                 d="M11.07,76.88c.43-.24.87-.47,1.31-.7l.33-.17c.36-.19.73-.37,1.1-.55l.25-.13,1.27-.6q.42-.21.87-.39l.55-.26.56-.24,1.14-.49,1.26-.51,0,0,.92-.35a0,0,0,0,1,0,0h0l1.05-.4c.44-.16.87-.33,1.31-.48l.85-.3,1.18-.4,1.41-.47L28,69.93l1-.3,1.42-.41L32,68.79q2.09-.56,4.23-1.05l1.43-.32c.9-.2,1.82-.39,2.74-.57l1.69-.33,1.21-.22,1.06-.18,1.51-.25,1-.16c.47-.07.94-.15,1.42-.21.7-.11,1.41-.2,2.12-.29s1.43-.19,2.14-.27l2.12-.24h.09l2.16-.2,2.22-.19,2.23-.16L63.23,64,64.39,64c.9,0,1.8-.08,2.71-.1l1.3,0q2.22-.06,4.47-.06c25.9,0,48.92,5.52,63.49,14.06,0,0,0,0,0,0h0l.61.37.14.08a52.55,52.55,0,0,1,4.59,3.12v1.93C122,73.82,85.68,60.69,42,68.18A154.16,154.16,0,0,0,11.07,76.88Z"
               />
               <path
                 className="cls-2"
                 d="M5.11,72.69a108.57,108.57,0,0,1,16.28-7.64C57.22,52.19,106.71,49.89,141.73,67v5l-.49-.25-1.43-.7c-.59-.29-1.19-.57-1.82-.85l-1.37-.6c-.31-.14-.63-.26-1-.39l-1.21-.5c-.85-.34-1.72-.68-2.6-1l-1.05-.38c-1.56-.55-3.16-1.08-4.82-1.59l-1.52-.46q-1.36-.41-2.76-.78l-1.54-.41-1.68-.43c-1.5-.36-3-.71-4.59-1l-1.55-.33c-.72-.14-1.45-.29-2.18-.41l-.8-.16-1.83-.33-2.47-.4-1.64-.25-1.1-.16c-.94-.13-1.87-.26-2.82-.37l-1-.13c-.77-.09-1.55-.19-2.34-.27l-2.29-.23-.1,0-2.35-.2c-.79-.07-1.6-.13-2.4-.18L86.65,59h-.07l-2-.12-1.26,0c-.9,0-1.8-.08-2.7-.1H80.4l-1.44,0c-1.6,0-3.21-.05-4.85-.05C46,58.62,21,64.13,5.15,72.67A0,0,0,0,1,5.11,72.69Z"
               />
               <image href="your-image-url.jpg" x="50" y="25" height="40" width="40" />
             </g>
           </g>
         </g>
       </svg>
            </div>
         </div>
        </a>
      </footer>
      
    </div>
    </div>
  );
}

export default Lewan;
