import './App.css';
import Lewan from './pages/Main';


function App() {
  return (
    <div className="">
   <Lewan/>
    </div> 
  );
}

export default App;
 